import SalesTax from 'sales-tax';

SalesTax.setTaxOriginCountry('US');

const TaxCalc = async (country, state) => {
  let countryCode;
  let stateCode;

  // COUNTRY
  switch (country.toUpperCase()) {
    case 'ARGENTINA':
      countryCode = 'AR';
      break;

    case 'AUSTRALIA':
      countryCode = 'AU';
      break;

    case 'AUSTRIA':
      countryCode = 'AT';
      break;

    case 'BELGIUM':
      countryCode = 'BE';
      break;

    case 'BOLIVIA':
      countryCode = 'BO';
      break;

    case 'BRAZIL':
      countryCode = 'BR';
      break;

    case 'BULGARIA':
      countryCode = 'BG';
      break;

    case 'CANADA':
      countryCode = 'CA';
      break;

    case 'CHILE':
      countryCode = 'CL';
      break;

    case 'CHINA':
      countryCode = 'CN';
      break;

    case 'HONG KONG':
      countryCode = 'HK';
      break;

    case 'COLUMBIA':
      countryCode = 'CO';
      break;

    case 'COSTA RICA':
      countryCode = 'CR';
      break;

    case 'CHECK REPUBLIC':
      countryCode = 'CZ';
      break;

    case 'DENMARK':
      countryCode = 'DK';
      break;

    case 'DOMINICAN REPUBLIC':
      countryCode = 'DO';
      break;

    case 'EGYPT':
      countryCode = 'EG';
      break;

    case 'EL SALVDARO':
      countryCode = 'SV';
      break;

    case 'FINLAND':
      countryCode = 'FI';
      break;

    case 'FRANCE':
      countryCode = 'FR';
      break;

    case 'FRENCH GUIANA':
      countryCode = 'GF';
      break;

    case 'FRENCH POLYNESIA':
      countryCode = 'PF';
      break;

    case 'GERMANY':
      countryCode = 'DE';
      break;

    case 'GREECE':
      countryCode = 'GR';
      break;

    case 'GREENLAND':
      countryCode = 'GL';
      break;

    case 'GUATEMALA':
      countryCode = 'GT';
      break;

    case 'HONDURAS':
      countryCode = 'HN';
      break;

    case 'HUNGARY':
      countryCode = 'HU';
      break;

    case 'ICELAND':
      countryCode = 'IS';
      break;

    case 'INDIA':
      countryCode = 'IN';
      break;

    case 'INDONESIA':
      countryCode = 'ID';
      break;

    case 'IRELAND':
      countryCode = 'IE';
      break;

    case 'ITALY':
      countryCode = 'IT';
      break;

    case 'JAMAICA':
      countryCode = 'JM';
      break;

    case 'JAPAN':
      countryCode = 'JP';
      break;

    case 'MEXICO':
      countryCode = 'MX';
      break;

    case 'NETHERLANDS':
      countryCode = 'NL';
      break;

    case 'NEW ZEALAND':
      countryCode = 'NZ';
      break;

    case 'NORWAY':
      countryCode = 'No';
      break;

    case 'PHILLIPINES':
      countryCode = 'PH';
      break;

    case 'POLAND':
      countryCode = 'PL';
      break;

    case 'PORTUGAL':
      countryCode = 'PT';
      break;

    case 'PUERTO RICO':
      countryCode = 'PR';
      break;

    case 'SOUTH AFRICA':
      countryCode = 'ZA';
      break;

    case 'SPAIN':
      countryCode = 'ES';
      break;

    case 'SWEDEN':
      countryCode = 'Se';
      break;

    case 'SWITZERLAND':
      countryCode = 'SZ';
      break;

    case 'TAIWAN':
      countryCode = 'TW';
      break;

    case 'THAILAND':
      countryCode = 'TH';
      break;

    case 'UNITED KINGDOM':
      countryCode = 'GB';
      break;

    case 'UNITED STATES':
      countryCode = 'US';
      break;

    case 'URUGUAY':
      countryCode = 'UY';
      break;

    case 'VENEZUELA':
      countryCode = 'VE';
      break;

    case 'VIRGIN ISLANDS, BRITISH':
      countryCode = 'VG';
      break;

    case 'VIRGIN ISLANDS, U.S.':
      countryCode = 'VI';
      break;

    default:
      countryCode = country;
      break;
  }

  // STATE
  switch (state.toUpperCase()) {
    case 'ALABAMA':
      stateCode = 'AL';
      break;

    case 'ALASKA':
      stateCode = 'AK';
      break;

    case 'AMERICAN SAMOA':
      stateCode = 'AS';
      break;

    case 'ARIZONA':
      stateCode = 'AZ';
      break;

    case 'ARKANSAS':
      stateCode = 'AR';
      break;

    case 'CALIFORNIA':
      stateCode = 'CA';
      break;

    case 'COLORADO':
      stateCode = 'CO';
      break;

    case 'CONNECTICUT':
      stateCode = 'CT';
      break;

    case 'DELAWARE':
      stateCode = 'DE';
      break;

    case 'DISTRICT OF COLUMBIA':
      stateCode = 'DC';
      break;

    case 'FLORIDA':
      stateCode = 'FL';
      break;

    case 'GEORGIA':
      stateCode = 'GA';
      break;

    case 'GUAM':
      stateCode = 'GU';
      break;

    case 'HAWAII':
      stateCode = 'HI';
      break;

    case 'IDAHO':
      stateCode = 'ID';
      break;

    case 'ILLINOIS':
      stateCode = 'IL';
      break;

    case 'INDIANA':
      stateCode = 'IN';
      break;

    case 'IOWA':
      stateCode = 'IA';
      break;

    case 'KANSAS':
      stateCode = 'KS';
      break;

    case 'KENTUCKY':
      stateCode = 'KY';
      break;

    case 'LOUISIANA':
      stateCode = 'LA';
      break;

    case 'MAINE':
      stateCode = 'ME';
      break;

    case 'MARYLAND':
      stateCode = 'MD';
      break;

    case 'MASSACHUSETTS':
      stateCode = 'MA';
      break;

    case 'MICHIGAN':
      stateCode = 'MI';
      break;

    case 'MINNESOTA':
      stateCode = 'MN';
      break;

    case 'MISSISSIPPI':
      stateCode = 'MS';
      break;

    case 'MISSOURI':
      stateCode = 'MO';
      break;

    case 'MONTANA':
      stateCode = 'MT';
      break;

    case 'NEBRASKS':
      stateCode = 'NE';
      break;

    case 'NEVADA':
      stateCode = 'NV';
      break;

    case 'NEW HAMPSHIRE':
      stateCode = 'NH';
      break;

    case 'NEW JERSEY':
      stateCode = 'NJ';
      break;

    case 'NEW MEXICO':
      stateCode = 'NM';
      break;

    case 'NEW YORK':
      stateCode = 'NY';
      break;

    case 'NORTH CAROLINA':
      stateCode = 'NC';
      break;

    case 'NORTH DAKOTA':
      stateCode = 'ND';
      break;

    case 'NORTHERN MARIANA IS':
      stateCode = 'MP';
      break;

    case 'OHIO':
      stateCode = 'OH';
      break;

    case 'OKLAHOMA':
      stateCode = 'OK';
      break;

    case 'OREGON':
      stateCode = 'OR';
      break;

    case 'PENNSYLVANIA':
      stateCode = 'PA';
      break;

    case 'PUERTO RICO':
      stateCode = 'PR';
      break;

    case 'RHODE ISLAND':
      stateCode = 'RI';
      break;

    case 'SOUTH CAROLINA':
      stateCode = 'SC';
      break;

    case 'SOUTH DAKOTA':
      stateCode = 'SD';
      break;

    case 'TENNESSEE':
      stateCode = 'TN';
      break;

    case 'TEXAS':
      stateCode = 'TX';
      break;

    case 'UTAH':
      stateCode = 'UT';
      break;

    case 'VERMONT':
      stateCode = 'VT';
      break;

    case 'VIRGINIA':
      stateCode = 'VA';
      break;

    case 'VIRGIN ISLANDS':
      stateCode = 'VI';
      break;

    case 'WASHINGTON':
      stateCode = 'WA';
      break;

    case 'WEST VIRGINIA':
      stateCode = 'WV';
      break;

    case 'WISCONSIN':
      stateCode = 'WI';
      break;

    case 'WYOMING':
      stateCode = 'WY';
      break;

    default:
      stateCode = state;
      break;
  }

  const total = await SalesTax.getSalesTax(countryCode, stateCode);

  return total.rate;
};

export default TaxCalc;
