export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST';
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS';
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL';
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET';

export const PRODUCT_CREATE_REPORT_REQUEST = 'PRODUCT_CREATE_REPORT_REQUEST';
export const PRODUCT_CREATE_REPORT_SUCCESS = 'PRODUCT_CREATE_REPORT_SUCCESS';
export const PRODUCT_CREATE_REPORT_FAIL = 'PRODUCT_CREATE_REPORT_FAIL';
export const PRODUCT_CREATE_REPORT_RESET = 'PRODUCT_CREATE_REPORT_RESET';

export const PRODUCT_TOP_RATED_REQUEST = 'PRODUCT_TOP_RATED_REQUEST';
export const PRODUCT_TOP_RATED_SUCCESS = 'PRODUCT_TOP_RATED_SUCCESS';
export const PRODUCT_TOP_RATED_FAIL = 'PRODUCT_TOP_RATED_FAIL';

export const PRODUCT_LIST_MY_REQUEST = 'PRODUCT_LIST_MY_REQUEST';
export const PRODUCT_LIST_MY_SUCCESS = 'PRODUCT_LIST_MY_SUCCESS';
export const PRODUCT_LIST_MY_FAIL = 'PRODUCT_LIST_MY_FAIL';
export const PRODUCT_LIST_MY_RESET = 'PRODUCT_LIST_MY_RESET';
