import React from 'react';
import '../card.css';
import { Link } from 'react-router-dom';
import Rating from './Rating';
import PlayButton from './PlayButton';

const Product = ({ product }) => {
  const resizeImg = (originalUrl) => {
    const urlStart = 'https://res.cloudinary.com/beatshopper/image/upload/';
    const urlResizeIndicator = 'c_scale,w_600/';
    const urlEnd = originalUrl.slice(52);
    const resizedUrl = urlStart + urlResizeIndicator + urlEnd;
    return resizedUrl;
  };

  return (
    <>
      <div className='cardcontainer'>
        <div className='productcard'>
          <div>
            <Link
              to={`/product/${product._id}`}
              className='productimgcontainer'
            >
              <img
                className='productimg'
                src={resizeImg(product.imageUrl)}
                alt={product.name}
              />
            </Link>
          </div>
          <div className='info'>
            <Link to={`/product/${product._id}`}>
              {product.name.length < 20 ? (
                <h1 className='title'>{product.name}</h1>
              ) : (
                <h1 className='title'>
                  {product.name.substring(0, 19)}
                  <small>...</small>
                </h1>
              )}
            </Link>

            {product.producer.length < 23 ? (
              <h2 className='title'>{product.producer}</h2>
            ) : (
              <h2 className='title'>
                {product.producer.substring(0, 22)}
                <small>...</small>
              </h2>
            )}

            <Link to={`/product/${product._id}`}>
              <Rating
                value={product.rating}
                text={`${product.numReviews} reviews`}
                color='#0e63bd'
              />
            </Link>
            {product.price > 3.99 ? (
              <h5>${product.price.toFixed(2)}</h5>
            ) : (
              <h5>FREE</h5>
            )}
            {product.audioPreviewUrl && product.audioPreviewUrl.length > 12 && (
              <div className='controls'>
                <PlayButton url={product.audioPreviewUrl} />
              </div>
            )}
            <div className='details'>
              <Link to={`/product/${product._id}`}>
                <button aria-label='Get This Beat'>GET THIS BEAT</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
