import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Product from '../components/Card';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import Meta from '../components/Meta';
import ProductCarousel from '../components/ProductCarousel';
import { listProducts } from '../actions/productActions';

const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, products, page, pages, error } = productList;

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <Meta />
      {!keyword ? (
        <>
          <h2 className='text-center'>Featured Beats</h2>
          <div className='carousel-container'>
            <ProductCarousel />
          </div>
        </>
      ) : (
        <Link to='/' className='btn btn-light'>
          Back to All Beats
        </Link>
      )}

      {!keyword ? (
        <h1 className='text-center'>All Beats</h1>
      ) : (
        <h1 className='text-center'>Search Results</h1>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            {products.map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
          <div className='pagination-container'>
            <Paginate pages={pages} page={page} />
          </div>
        </>
      )}
    </>
  );
};

export default HomeScreen;
