import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

const SearchBox = () => {
  const history = useHistory();

  const [keyword, setKeyword] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push('/');
    }
  };

  return (
    <Form onSubmit={submitHandler} inline>
      <Form.Control
        type='text'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder='Search Beats...'
        className='mr-sm-2 ml-sm-5'
        size='sm'
      ></Form.Control>
      <Button
        type='submit'
        variant='outline-info'
        className='p-2 hide-sm'
        size='sm'
      >
        Search
      </Button>
    </Form>
  );
};

export default SearchBox;
