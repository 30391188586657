import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import Rating from '../components/Rating';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Meta from '../components/Meta';
import {
  getProductDetails,
  createProductReview,
  createProductReport,
} from '../actions/productActions';
import PlayButton from '../components/PlayButton';
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REPORT_RESET,
} from '../constants/productConstants';

const ProductScreen = ({ match, history }) => {
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState(null);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [reportWindowOpen, setReportWindowOpen] = useState(false);

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, product, error } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productCreateReview = useSelector((state) => state.productCreateReview);
  const {
    success: successProductReview,
    error: errorProductReview,
  } = productCreateReview;

  const productCreateReport = useSelector((state) => state.productCreateReport);
  const {
    success: successProductReport,
    error: errorProductReport,
  } = productCreateReport;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (successProductReview) {
      setMessage('Review Submitted');
      setSendSuccess(true);
      setRating(0);
      setComment('');
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    if (successProductReport) {
      setMessage('Report Submitted');
      setSendSuccess(true);
      setReason('');
      dispatch({ type: PRODUCT_CREATE_REPORT_RESET });
    }
    dispatch(getProductDetails(match.params.id));
  }, [dispatch, match, successProductReview, successProductReport]);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}`);
  };

  const reportWindowHandler = () => {
    setReportWindowOpen(!reportWindowOpen);
  };

  const submitReviewHandler = (e) => {
    e.preventDefault();
    dispatch(createProductReview(match.params.id, { rating, comment }));
  };

  const submitReportHandler = (e) => {
    e.preventDefault();
    dispatch(createProductReport(match.params.id, { reason }));
  };

  return (
    <div>
      <Link className='btn btn-light my-3' to='/'>
        Go Back
      </Link>
      {loading || !product ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={product.name} />
          {sendSuccess && <Message>{message}</Message>}
          <Row>
            <Col md={12}>
              <div className='company-logo-overlay'>
                <div className='product-image-container pb-5'>
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className='img-cover hide-sm'
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Image src={product.imageUrl} alt={product.name} fluid />
            </Col>
            <Col md={3}>
              <ListGroup className='text-center' variant='flush'>
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                  <h6>
                    <small>by</small> {product.producer}
                  </h6>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <small className='text-uppercase'>
                    GENRE: {product.category}
                  </small>
                </ListGroup.Item>
                <ListGroup.Item>
                  <small>BEATID: {product.shortId}</small>
                </ListGroup.Item>
              </ListGroup>
            </Col>

            {product.isListed &&
              !product.isFlagged &&
              product.audioPreviewUrl.length > 12 && (
                <Col md={3}>
                  <Card>
                    <ListGroup className='text-center' variant='flush'>
                      <ListGroup.Item>
                        <Row>
                          <Col>Price:</Col>
                          <Col>
                            {product.price >= 3.99 ? (
                              <span>
                                ${product.price && product.price.toFixed(2)}
                              </span>
                            ) : (
                              <span>FREE</span>
                            )}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>License:</Col>
                          <Col style={{ textTransform: 'uppercase' }}>
                            {product.license}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className='controls'>
                          <PlayButton url={product.audioPreviewUrl} />
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {product.price >= 3.99 && (
                          <Button
                            onClick={addToCartHandler}
                            className='btn-block'
                            type='button'
                            disabled={product.isListed === 0}
                          >
                            Add To Cart
                          </Button>
                        )}
                        {userInfo && product.price <= 3.98 && (
                          <a href={product.audioUrl} download>
                            <Button
                              className='btn-block'
                              type='button'
                              disabled={product.isListed === 0}
                            >
                              DOWNLOAD
                            </Button>
                          </a>
                        )}
                        {!userInfo && product.price <= 3.98 && (
                          <Link to='/login'>
                            <Button
                              className='btn-block'
                              type='button'
                              disabled={product.isListed === 0}
                            >
                              LOGIN TO DOWNLOAD
                            </Button>
                          </Link>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              )}
          </Row>
          <Row>
            <Col>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <small>Description: {product.desc}</small>{' '}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          {userInfo && userInfo.isAdmin && (
            <Row>
              <Col md={12}>
                <h2 style={{ color: 'red' }}>Reports</h2>
                {(!product.reports || product.reports.length === 0) && (
                  <Message>No reports</Message>
                )}
                <ListGroup variant='flush'>
                  {product.reports &&
                    product.reports.map((report) => (
                      <ListGroup.Item key={report._id}>
                        <strong>{report.name}</strong>
                        <p>
                          {report.createdAt.substring(5, 7)}/
                          {report.createdAt.substring(8, 10)}/
                          {report.createdAt.substring(0, 4)}
                        </p>
                        <p>"{report.reason}"</p>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ListGroup variant='flush' className='text-center'>
                <ListGroup.Item>
                  <Button
                    variant='light'
                    className='report-container'
                    onClick={reportWindowHandler}
                  >
                    {!reportWindowOpen ? (
                      <>
                        <i className='fas fa-flag' style={{ color: 'red' }}></i>
                        <small> Report</small>
                      </>
                    ) : (
                      <>
                        <i className='fas fa-times'></i> <small> CANCEL </small>
                      </>
                    )}
                  </Button>
                </ListGroup.Item>
                {reportWindowOpen && (
                  <ListGroup.Item>
                    <h2>Report This Beat</h2>
                    {errorProductReview && (
                      <Message variant='danger'>{errorProductReport}</Message>
                    )}
                    {userInfo ? (
                      <Form onSubmit={submitReportHandler}>
                        <Form.Group controlId='reason'>
                          <Form.Label>
                            Reason for Report{' '}
                            <Link to='/'>
                              <i className='fas fa-info-circle'></i>
                            </Link>
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            row='3'
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Button type='submit' variant='primary'>
                          Submit
                        </Button>
                      </Form>
                    ) : (
                      <Message>
                        {' '}
                        Please <Link to='/login'> sign in</Link> to report a
                        beat.
                      </Message>
                    )}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h2>Reviews</h2>
              {(!product.reviews || product.reviews.length === 0) && (
                <Message>No reviews</Message>
              )}
              <ListGroup variant='flush'>
                {product.reviews &&
                  product.reviews.map((review) => (
                    <ListGroup.Item key={review._id}>
                      <strong>{review.name}</strong>
                      <Rating value={review.rating} text={``} />
                      <p>
                        {review.createdAt.substring(5, 7)}/
                        {review.createdAt.substring(8, 10)}/
                        {review.createdAt.substring(0, 4)}
                      </p>
                      <p>"{review.comment}"</p>
                    </ListGroup.Item>
                  ))}
                <ListGroup.Item>
                  <h2>Review this Beat</h2>
                  {errorProductReview && (
                    <Message variant='danger'>{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitReviewHandler}>
                      <Form.Group controlId='rating'>
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as='select'
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=''>Select ...</option>
                          <option value='1'>1 - Bad</option>
                          <option value='2'>2 - Decent</option>
                          <option value='3'>3 - Good</option>
                          <option value='4'>4 - Great</option>
                          <option value='5'>5 - Amazing</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='comment'>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as='textarea'
                          row='3'
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button type='submit' variant='primary'>
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      {' '}
                      Please <Link to='/login'> sign in</Link> to create a
                      review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ProductScreen;
