import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import Loader from './Loader';
import Message from './Message';
import { listTopRatedFeaturedProducts } from '../actions/productActions';

const ProductCarousel = () => {
  const dispatch = useDispatch();

  const resizeImg = (originalUrl) => {
    const urlStart = 'https://res.cloudinary.com/beatshopper/image/upload/';
    const urlResizeIndicator = 'c_scale,w_800/';
    const urlEnd = originalUrl.slice(52);
    const resizedUrl = urlStart + urlResizeIndicator + urlEnd;
    return resizedUrl;
  };

  const productTopRated = useSelector((state) => state.productTopRated);
  const { loading, error, products } = productTopRated;

  useEffect(() => {
    dispatch(listTopRatedFeaturedProducts());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Carousel pause='hover' className='bg-dark' touch>
      {products.map((product) => (
        <Carousel.Item key={product._id}>
          <Link to={`/product/${product._id}`}>
            <Image
              className='carousel-image'
              src={resizeImg(product.imageUrl)}
              alt={product.name}
              fluid
            />
            <Carousel.Caption className='carousel-caption'>
              <h5>{product.name}</h5>
              <h6>
                <small>by</small> {product.producer}
              </h6>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductCarousel;
