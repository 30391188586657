import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { listMyOrders } from '../actions/orderActions';
import { listMyProducts, deleteProduct } from '../actions/productActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';

const ProfileScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  const productListMy = useSelector((state) => state.productListMy);
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
  } = productListMy;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails('profile'));
      } else {
        dispatch(listMyOrders());
        setName(user.name);
        setEmail(user.email);
      }
    }
    if (userInfo && (userInfo.isSeller || userInfo.isAdmin)) {
      dispatch(listMyProducts());
    }
  }, [dispatch, history, user, userInfo, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }));
    }
  };

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you would like to delete this beat?')) {
      dispatch(deleteProduct(id));
    }
  };

  return (
    <Row>
      <Col md={3}>
        <h2 className='text-center'>User Profile</h2>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {success && <Message variant='success'>Profile Updated</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='confirmPassword'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary'>
            Update
          </Button>
        </Form>
      </Col>

      <Col md={9}>
        {loadingProducts ? (
          <Loader />
        ) : errorProducts ? (
          <Message variant='danger'>{errorProducts}</Message>
        ) : (
          <>
            {userInfo &&
              (userInfo.isSeller || userInfo.isAdmin) &&
              products.length < 1 && (
                <>
                  <h2 className='text-center'>My Beats</h2>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className='table-sm'
                    style={{ textAlign: 'center' }}
                  >
                    <thead>
                      <tr className='hide-sm'>
                        <th>
                          <small>PRODUCER(S)</small>
                        </th>
                        <th>
                          <small>BEAT NAME</small>
                        </th>
                        <th>
                          <small>PRICE</small>
                        </th>
                        <th>
                          <small>GENRE</small>
                        </th>
                        <th>
                          <small>Lstd</small>
                        </th>
                        <th>
                          <small>Ft.</small>
                        </th>
                        <th>
                          <small>Edit</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product) => (
                        <tr key={product._id}>
                          <td className='hide-sm'>{product.producer}</td>
                          <td>
                            <Link to={`/product/${product._id}`}>
                              <div>{product.name} </div>
                            </Link>
                          </td>
                          <td>
                            <small>${product.price.toFixed(2)}</small>
                          </td>
                          <td className='hide-sm'>{product.category}</td>
                          <td className='hide-sm'>
                            {product.isListed === true ? (
                              <i className='fas fa-list icon-active'></i>
                            ) : (
                              <i className='fas fa-list icon-inactive'></i>
                            )}
                          </td>
                          <td>
                            {product.isPremium === true ? (
                              <i className='fas fa-gem icon-active'></i>
                            ) : (
                              <i className='fas fa-gem icon-inactive'></i>
                            )}
                          </td>
                          <td>
                            <LinkContainer
                              to={`/admin/product/${product._id}/edit`}
                            >
                              <Button variant='light' className='btn-sm'>
                                <i className='fas fa-edit'></i>
                              </Button>
                            </LinkContainer>
                            {userInfo.isAdmin && (
                              <Button
                                variant='danger'
                                className='btn-sm'
                                onClick={() => deleteHandler(product._id)}
                              >
                                <i className='fas fa-trash'></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
          </>
        )}

        <h2 className='text-center'>My Orders</h2>
        {loadingOrders ? (
          <Loader />
        ) : errorOrders ? (
          <Message variant='danger'>{errorOrders}</Message>
        ) : orders.length < 1 ? (
          <Message>No Orders</Message>
        ) : (
          <Table
            striped
            bordered
            hover
            responsive
            className='table-sm'
            style={{ textAlign: 'center' }}
          >
            <thead>
              <tr>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                {/* <th>FILE SENT</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>
                    <small>
                      {order.createdAt.substring(5, 10)}-
                      {order.createdAt.substring(2, 4)}
                    </small>
                  </td>
                  <td>${order.orderPrice.toFixed(2)}</td>
                  <td>
                    {order.isPaid ? (
                      <i className='fas fa-check icon-success'></i>
                    ) : (
                      <i className='fas fa-times icon-inactive'></i>
                    )}
                  </td>
                  {/* <td>
                    {order.isSent ? (
                      <i className='fas fa-check icon-success'></i>
                    ) : (
                      <i className='fas fa-times icon-inactive'></i>
                    )}
                  </td> */}
                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button className='btn-sm' variant='dark'>
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
};

export default ProfileScreen;
