import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { getProductDetails, updateProduct } from '../actions/productActions';
import {
  PRODUCT_UPDATE_RESET,
  PRODUCT_DETAILS_RESET,
} from '../constants/productConstants';

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [audioPreviewUrl, setAudioPreviewUrl] = useState('');
  const [producer, setProducer] = useState('');
  const [category, setCategory] = useState('');
  const [desc, setDesc] = useState('');
  const [uploading, setUploading] = useState(false);
  const [isListed, setIsListed] = useState('');
  const [isFlagged, setIsFlagged] = useState('');
  const [isPremium, setIsPremium] = useState('');

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && !userInfo.isAdmin && !userInfo.isSeller) {
      history.push('/');
    }
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch({ type: PRODUCT_DETAILS_RESET });
      history.push('/admin/productList');
    } else {
      if (!product || !product.name || product._id !== productId) {
        dispatch(getProductDetails(productId));
      } else {
        setName(product.name);
        setPrice(product.price);
        setImageUrl(product.imageUrl);
        setAudioUrl(product.audioUrl);
        setAudioPreviewUrl(product.audioPreviewUrl);
        setProducer(product.producer);
        setCategory(product.category);
        setDesc(product.desc);
        setIsListed(product.isListed);
        setIsFlagged(product.isFlagged);
        setIsPremium(product.isPremium);
      }
    }
  }, [
    dispatch,
    userInfo,
    history,
    product,
    productId,
    productUpdate,
    successUpdate,
  ]);

  const uploadImgFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post('/api/uploadImg', formData, config);

      setImageUrl(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadAudioPreviewFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('mp3', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        '/api/uploadMp3/preview',
        formData,
        config
      );

      setAudioPreviewUrl(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadAudioFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('mp3', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        '/api/uploadMp3/full',
        formData,
        config
      );

      setAudioUrl(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        imageUrl,
        audioUrl,
        audioPreviewUrl,
        producer,
        category,
        desc,
        isListed,
        isFlagged,
        isPremium,
      })
    );
  };

  return (
    <>
      <Link to='/admin/productlist' className='btn- btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1 className='text-center'>Edit Beat Info</h1>
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='price'>
              <Form.Label>Price ($)</Form.Label>
              <Form.Control
                type='number'
                step='0.01'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='imageUrl'>
              <Form.Label>Image Url</Form.Label>
              <Form.Control
                type='text'
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              ></Form.Control>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadImgFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='audioUrl'>
              <Form.Label>
                Audio Url <small>(Full Deliverable Version)</small>
              </Form.Label>
              <Form.Control
                type='text'
                value={audioUrl}
                onChange={(e) => setAudioUrl(e.target.value)}
              ></Form.Control>
              <Form.File
                id='audio-file'
                label='Choose File'
                custom
                onChange={uploadAudioFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='audioPreviewUrl'>
              <Form.Label>
                Audio Preview Url <small>(public sample)</small>
              </Form.Label>
              <Form.Control
                type='text'
                value={audioPreviewUrl}
                onChange={(e) => setAudioPreviewUrl(e.target.value)}
              ></Form.Control>
              <Form.File
                id='audio-preview-file'
                label='Choose File'
                custom
                onChange={uploadAudioPreviewFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='producer'>
              <Form.Label>Producer</Form.Label>
              <Form.Control
                type='text'
                value={producer}
                onChange={(e) => setProducer(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='category'>
              <Form.Label>Genre</Form.Label>
              <Form.Control
                type='text'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='desc'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type='text'
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='islisted'>
              <Form.Check
                type='checkbox'
                label='Listed'
                checked={isListed}
                onChange={(e) => setIsListed(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            {userInfo && userInfo.isAdmin && (
              <>
                <Form.Group controlId='isflagged'>
                  <Form.Check
                    type='checkbox'
                    label='Flagged'
                    checked={isFlagged}
                    onChange={(e) => setIsFlagged(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId='ispremium'>
                  <Form.Check
                    type='checkbox'
                    label='Premium'
                    checked={isPremium}
                    onChange={(e) => setIsPremium(e.target.checked)}
                  ></Form.Check>
                </Form.Group>
              </>
            )}

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
