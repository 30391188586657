import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { saveDeliveryInfo } from '../actions/cartActions';

const DeliveryScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { deliveryInfo } = cart;

  const [email, setEmail] = useState(deliveryInfo.email);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();

    // Check emails match
    if (email !== confirmEmail) {
      setMessage('Emails do not match');
    } else {
      dispatch(
        saveDeliveryInfo({
          email,
          address1,
          address2,
          city,
          state,
          country,
          zipCode,
        })
      );

      // Check for US Address
      if (state === '' && country === 'United States') {
        setMessage('Please fill out state field to continue');
        return;
      }

      // Check Country is Select
      if (country === '' || country === 'Select Country') {
        setMessage('Please select your country to continue');
        return;
      }

      history.push('/payment');
    }
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 />
      <h2>Email Address</h2>
      {/* <h6 className='p-2'>
        This is the email address where your beat will be sent. Please ensure it
        is correct before submitting.
      </h6> */}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter Email'
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='confirmEmail'>
          <Form.Label>Confirm Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Confirm Email'
            value={confirmEmail}
            required
            onChange={(e) => setConfirmEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {message && <Message variant='danger'>{message}</Message>}

        <h2>Billing Address</h2>
        <h6 className='p-2'>
          Your billing address is needed for the processing of your order and
          calculating sales tax.
        </h6>

        <Form.Group controlId='address1'>
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control
            type='address1'
            placeholder='Address'
            value={address1}
            required
            onChange={(e) => setAddress1(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='address2'>
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
            type='address2'
            placeholder='Apt/Suite/Etc.'
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='city'
            placeholder='City'
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='state'>
          <Form.Label>State</Form.Label>
          <Form.Control
            type='state'
            placeholder='State'
            value={state}
            onChange={(e) => setState(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='zipCode'>
          <Form.Label>Zip-Code</Form.Label>
          <Form.Control
            type='zipCode'
            placeholder='Zip-Code'
            value={zipCode}
            required
            onChange={(e) => setZipCode(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            as='select'
            type='country'
            required
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value=''>Select Country</option>
            <option value='Argentina'>Argentina</option>
            <option value='Australia'>Australia</option>
            <option value='Austria'>Austria</option>
            <option value='Belgium'>Belgium</option>
            <option value='Bolivia'>Bolivia</option>
            <option value='Brazil'>Brazil</option>
            <option value='British Virgin Islands'>
              British Virgin Islands{' '}
            </option>
            <option value='Bulgaria'>Bulgaria</option>
            <option value='Canada'>Canada</option>
            <option value='Chile'>Chile</option>
            <option value='China'>China</option>
            <option value='Colombia'>Colombia</option>
            <option value='Costa Rica'>Costa Rica</option>
            <option value='Czech Republic'>Czech Republic</option>
            <option value='Denmark'>Denmark</option>
            <option value='Dominican Republic'>Dominican Republic</option>
            <option value='El Salvador'>El Salvador</option>
            <option value='Finland'>Finland</option>
            <option value='France'>France</option>
            <option value='French Guiana'>French Guiana</option>
            <option value='French Polynesia'>French Polynesia</option>
            <option value='Germany'>Germany</option>
            <option value='Greece'>Greece</option>
            <option value='Greenland'>Greenland</option>
            <option value='Guatemala'>Guatemala</option>
            <option value='Honduras'>Honduras</option>
            <option value='Hong Kong'>Hong Kong</option>
            <option value='Hungary'>Hungary</option>
            <option value='Iceland'>Iceland</option>
            <option value='India'>India</option>
            <option value='Indonesia'>Indonesia</option>
            <option value='Ireland'>Ireland</option>
            <option value='Italy'>Italy</option>
            <option value='Jamaica'>Jamaica</option>
            <option value='Japan'>Japan</option>
            <option value='Mexico'>Mexico</option>
            <option value='Netherlands'>Netherlands</option>
            <option value='New Zealand'>New Zealand</option>
            <option value='Norway'>Norway</option>
            <option value='Philippines'>Philippines</option>
            <option value='Poland'>Poland</option>
            <option value='Portugal'>Portugal</option>
            <option value='Puerto Rico'>Puerto Rico</option>
            <option value='South Africa'>South Africa</option>
            <option value='Spain'>Spain</option>
            <option value='Sweden'>Sweden</option>
            <option value='Switzerland'>Switzerland</option>
            <option value='Taiwan, Province of China'>
              Taiwan, Province of China
            </option>
            <option value='Thailand'>Thailand</option>
            <option value='United Kingdom'>United Kingdom</option>
            <option value='United States'>United States</option>
            <option value='Uruguay'>Uruguay</option>
            <option value='Venezuela'>Venezuela</option>
            <option value='Virgin Islands, British'>
              Virgin Islands, British
            </option>
            <option value='Virgin Islands, U.S.'>Virgin Islands, U.S.</option>
          </Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Continue
        </Button>
      </Form>
    </FormContainer>
  );
};

export default DeliveryScreen;
