import axios from 'axios';
import {
  CART_ADD_ITEM,
  CART_ITEMS_RESET,
  CART_REMOVE_ITEM,
  CART_SAVE_DELIVERY_INFO,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants';

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`);

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      imageUrl: data.imageUrl,
      audioUrl: data.audioUrl,
      price: data.price,
      countInStock: data.countInStock,
      qty,
    },
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const saveDeliveryInfo = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_DELIVERY_INFO,
    payload: data,
  });

  localStorage.setItem('deliveryInfo', JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem('payentMethod', JSON.stringify(data));
};

export const emptyCartItems = () => (dispatch) => {
  dispatch({ type: CART_ITEMS_RESET });

  localStorage.setItem('cartItems', []);
};
