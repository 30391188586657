import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { createProduct } from '../actions/productActions';

const ProductCreateScreen = ({ history }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [audioPreviewUrl, setAudioPreviewUrl] = useState('');
  const [producer, setProducer] = useState('');
  const [category, setCategory] = useState('');
  const [desc, setDesc] = useState('');
  const [uploading, setUploading] = useState(false);
  const [isListed, setIsListed] = useState(true);
  const [isFlagged, setIsFlagged] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && !userInfo.isAdmin && !userInfo.isSeller) {
      history.push('/');
    }
  }, [history, userInfo]);

  const uploadImgFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post('/api/uploadImg', formData, config);

      setImageUrl(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadAudioPreviewFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('mp3', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        '/api/uploadMp3/preview',
        formData,
        config
      );

      setAudioPreviewUrl(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadAudioFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('mp3', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post(
        '/api/uploadMp3/full',
        formData,
        config
      );

      setAudioUrl(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProduct({
        name,
        price,
        imageUrl,
        audioUrl,
        audioPreviewUrl,
        producer,
        category,
        desc,
        isListed,
        isFlagged,
        isPremium,
      })
    );
    history.push('/profile');
  };

  return (
    <>
      <Link to='/admin/productlist' className='btn- btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1 className='text-center'>Add A Beat</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Beat Name'
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='price'>
            <Form.Label>Price ($)</Form.Label>
            <Form.Control
              type='number'
              step='0.01'
              value={price}
              required
              onChange={(e) => setPrice(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='imageUrl'>
            <Form.Label>Image Url</Form.Label>
            <Form.Control
              type='text'
              value={imageUrl}
              placeholder='Enter URL to an image, or upload below.'
              required
              onChange={(e) => setImageUrl(e.target.value)}
            ></Form.Control>
            <Form.File
              id='image-file'
              label='Choose File'
              custom
              onChange={uploadImgFileHandler}
            ></Form.File>
            {uploading && <Loader />}
          </Form.Group>

          <Form.Group controlId='audioUrl'>
            <Form.Label>
              Audio Url <small>(Full Deliverable Version)</small>
            </Form.Label>
            <Form.Control
              type='text'
              value={audioUrl}
              placeholder='Enter URL to the MP3, or upload below.'
              required
              onChange={(e) => setAudioUrl(e.target.value)}
            ></Form.Control>
            <Form.File
              id='audio-file'
              label='Choose File'
              custom
              onChange={uploadAudioFileHandler}
            ></Form.File>
            {uploading && <Loader />}
          </Form.Group>

          <Form.Group controlId='audioPreviewUrl'>
            <Form.Label>
              Audio Preview Url <small>(public sample)</small>
            </Form.Label>
            <Form.Control
              type='text'
              value={audioPreviewUrl}
              placeholder='Enter URL to the MP3, or upload below.'
              required
              onChange={(e) => setAudioPreviewUrl(e.target.value)}
            ></Form.Control>
            <Form.File
              id='audio-preview-file'
              label='Choose File'
              custom
              onChange={uploadAudioPreviewFileHandler}
            ></Form.File>
            {uploading && <Loader />}
          </Form.Group>

          <Form.Group controlId='producer'>
            <Form.Label>Producer</Form.Label>
            <Form.Control
              type='text'
              value={producer}
              placeholder='Producer Name(s)'
              required
              onChange={(e) => setProducer(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='category'>
            <Form.Label>Genre</Form.Label>
            <Form.Control
              type='text'
              value={category}
              placeholder='Genre (Hip-Hop, Lo-Fi, etc.)'
              required
              onChange={(e) => setCategory(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='desc'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type='text'
              value={desc}
              placeholder='Desc. (instruments used, style, etc.)'
              required
              onChange={(e) => setDesc(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='islisted'>
            <Form.Check
              type='checkbox'
              label='Listed'
              checked={isListed}
              onChange={(e) => setIsListed(e.target.checked)}
            ></Form.Check>
          </Form.Group>

          {userInfo && userInfo.isAdmin && (
            <>
              <Form.Group controlId='isflagged'>
                <Form.Check
                  type='checkbox'
                  label='Flagged'
                  checked={isFlagged}
                  onChange={(e) => setIsFlagged(e.target.checked)}
                ></Form.Check>
              </Form.Group>

              <Form.Group controlId='ispremium'>
                <Form.Check
                  type='checkbox'
                  label='Premium'
                  checked={isPremium}
                  onChange={(e) => setIsPremium(e.target.checked)}
                ></Form.Check>
              </Form.Group>
            </>
          )}

          <Button type='submit' variant='primary'>
            Submit
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default ProductCreateScreen;
