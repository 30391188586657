import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, desc, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={desc} />
      <meta name='keywords' content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'BeatShopper.com',
  desc:
    'Buy beats / instrumentals online. Royalty free. Buy and lease rap beats, hip hop beats, trap beats, and other instrumentals. Download free beats easily on BeatShopper',
  keywords:
    'beats, instrumentals, buy beats, purchase instrumentals, cheap beats, free rap beats, sell beats, beat marketplace, beatshopper',
};

export default Meta;
