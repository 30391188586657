import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listAllProducts, deleteProduct } from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

const ProductListScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
    if (!userInfo.isAdmin) {
      history.push('/login');
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listAllProducts());
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you would like to delete this beat?')) {
      dispatch(deleteProduct(id));
    }
  };

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1 className='text-center'>Beats</h1>
        </Col>
      </Row>
      <Row>
        {' '}
        <Col className='text-center'>
          <Link to='/seller/product/create'>
            <Button className='my-3'>
              <i className='fas fa-headphones'></i> Add New Beat
            </Button>
          </Link>
        </Col>
      </Row>
      {errorDelete && <Message>{errorDelete}</Message>}
      {errorCreate && <Message>{errorCreate}</Message>}
      {loading || loadingDelete || loadingCreate ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          className='table-sm'
          style={{ textAlign: 'center' }}
        >
          <thead>
            <tr className='hide-sm'>
              <th>
                <small>ID</small>
              </th>
              <th>
                <small>PRODUCER(S)</small>
              </th>
              <th>
                <small>BEAT NAME</small>
              </th>
              <th>
                <small>PRICE</small>
              </th>
              <th>
                <small>GENRE</small>
              </th>
              <th>
                <small>Flgd</small>
              </th>
              <th>
                <small>Lstd</small>
              </th>
              <th>
                <small>Vis</small>
              </th>
              <th>
                <small>Ft.</small>
              </th>
              <th>
                <small>Edit/Delete</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id}>
                <td className='hide-sm'>
                  <small>{product._id}</small>
                  <br></br>
                  <small>BID: {product.shortId}</small>
                </td>
                <td>{product.producer}</td>
                <td>
                  <Link to={`/product/${product._id}`}>
                    <div>{product.name} </div>
                  </Link>
                </td>
                <td className='hide-sm'>${product.price.toFixed(2)}</td>
                <td className='hide-sm'>{product.category}</td>
                <td>
                  {product.isFlagged === true ? (
                    <i className='fas fa-flag icon-danger'></i>
                  ) : (
                    <i className='fas fa-flag icon-active'></i>
                  )}
                  <small className='icon-active'>{product.numReports}</small>
                </td>
                <td>
                  {product.isListed === true ? (
                    <i className='fas fa-list icon-active'></i>
                  ) : (
                    <i className='fas fa-list icon-inactive'></i>
                  )}
                </td>
                <td>
                  {product.isListed === false ||
                  product.isFlagged === true ||
                  product.audioUrl.length < 10 ||
                  product.audioPreviewUrl.length < 10 ? (
                    <i className='far fa-eye icon-inactive'></i>
                  ) : (
                    <i className='far fa-eye icon-active'></i>
                  )}
                </td>
                <td>
                  {product.isPremium === true ? (
                    <i className='fas fa-gem icon-active'></i>
                  ) : (
                    <i className='fas fa-gem icon-inactive'></i>
                  )}
                </td>
                <td>
                  <LinkContainer to={`/admin/product/${product._id}/edit`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>
                  <Button
                    variant='danger'
                    className='btn-sm'
                    onClick={() => deleteHandler(product._id)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ProductListScreen;
