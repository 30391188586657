import React from 'react';
import { Link } from 'react-router-dom';
// import Loader from '../components/Loader';

const LandingScreen = ({ props }) => {
  return (
    <>
      <div id='landing-container'>
        <div className='landing-cta'>
          <h1>PREVIEW, PURCHASE, and DOWNLOAD BEATS EASILY</h1>
          <h2>SO YOU CAN GET BACK TO MAKING MUSIC</h2>
          <div className='landing-enter-button'>
            <Link to='/beats'>
              <div className='button btn-primary'>GET BEATS</div>
            </Link>
          </div>
        </div>
      </div>
      <div className='cta-container'>
        <div className='cta-blocks'>
          <div className='cta-block1'>
            <h1>SELL BEATS</h1>
            <p>
              After signing up, in your profile section there is a button
              labeled "START SELLING BEATS". Click it and you will be taken to a
              form where you can submit samples of your beats and some info
              about yourself and our team will review it within 48 hours.
            </p>
            <Link to='/register'>
              <div className='button btn-dark'>SIGN UP</div>
            </Link>
          </div>
          <div className='cta-block2'>
            <h1>BUY BEATS</h1>
            <p>
              To start listening to beats simply click the button below. When
              you find something you like, if it is free you can download it
              immediately. For all beats with a price tag, simply sign up for
              Beat Shopper and add the beat to your cart to purchase it.
            </p>
            <Link to='/beats'>
              <div className='button btn-light'>GET BEATS</div>
            </Link>
          </div>
        </div>
        <div className='cta'>
          <Link to='/beats'>
            <div className='button btn-primary btn-round'>START LISTENING</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default LandingScreen;
